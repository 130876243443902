.Container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

footer{
    margin-top: 5%;
    width: 100vw;
    animation: backgroundColor-change 20s infinite;
}

footer:hover{
    animation: backgroundColor-change 2s infinite;
}

@keyframes backgroundColor-change{
    0% {background-color: pink;}
    25% {background-color: lightblue;}
    50% {background-color: lightgreen;}
    75% {background-color: lightblue;}
    100% {background-color: pink;}
}

@media (max-width: 600px){
    .Container{
        flex-direction: column;
    }
}