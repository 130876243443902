.Projects{
    margin: 10vh;
}

.Project{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 5%;
    height: 50vh;
}

@media (max-width: 600px){
    .Projects{
        margin: 0 5vw;
    }
}